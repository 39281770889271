import * as React from "react"
import './css/modal.scss'

export function Modal ({ handleClose, show, children }) {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
  
    return (
      <div className={showHideClassName}>

        <section className="modal-main">
        <div class="modal-content">
            {children}
        </div>
        </section>
      </div>
    )
}
