import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { PeopleCard } from "./people-card"

export function Team() {
    const data = useStaticQuery(graphql`
    query TeamQuery {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(team)/"}} sort: {fields: [frontmatter___order], order: ASC}) {
          nodes {
            frontmatter {
              credentials
              name
              title
              order
              bioLink
              linkedIn
              featuredImage {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 200, quality: 90)
                }
              }
            }
          }
        }
      }

  `)

  return (
    <div className="container mt-3 pt-md-2 pt-lg-4 pb-2 pb-md-4 pb-lg-5">
      <a id="team" className="jumpto-offset"></a>
      <h2 className="h1 section--header text-center pb-2 pb-md-0 mb-4 mb-md-5">Management</h2>
        <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
        {data.allMarkdownRemark.nodes.map(t =>(
            <PeopleCard
                key={t.frontmatter.name}
                name={t.frontmatter.name}
                bioLink={t.frontmatter.bioLink}
                linkedIn={t.frontmatter.linkedIn}
                credentials={t.frontmatter.credentials}
                image={t.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                title={t.frontmatter.title}></PeopleCard>
            ))}
        </div>
    </div>
  )
}



