import * as React from "react"
import {Layout} from "../components/layout.jsx"
import { Team } from "../components/team"
import { Leadership } from "../components/leadership"
import Seo from "../components/seo.jsx"

const CompanyPage = () => {
  return (
    <Layout>
      <Seo title="Company"></Seo>
      <div class="container position-relative">
          <div class="position-relative pt-5">
            <div class="row position-relative justify-content-center zindex-3 py-lg-4">
              <div class="col-11 col-md-10 col-lg-8 text-center">
                <p class="fs-xl pb-3 mb-4">
                Our team of data scientists, microbiologists, computational chemists, and chemical biologists is supported by a Board of Directors with a wealth of experience in medicinal chemistry, drug discovery and development, and venture capital
                </p>
              </div>
            </div>
          </div>
        </div>
      <section className="position-relative">
        <Team></Team>
        <div class="bg-secondary">
          <Leadership></Leadership>
        </div>
      </section>
    </Layout>
  )
}

export default CompanyPage
