import React, {useState} from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import {Link} from "gatsby"
import {Modal} from './modal'

import './css/people-card.scss'

export function PeopleCard({name, title, credentials, image, bio, bioSlug, bioLink, linkedIn}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
  };
  const imageContainerClasses = `people-card--image-container mx-auto mb-3 ${ bioSlug || bio ? 'has-bio' : ''}`
  const renderLinkedIn = (linkedIn) => {
    return (
      <a href={linkedIn} target="_blank" rel="noreferrer" className="btn btn-icon btn-outline-secondary  btn-sm me-2">
        <i className="bx bxl-linkedin"></i>
      </a>
    )
  }

  const renderBioLink = (bioLink) => {
    return (
      <a href={bioLink} className="btn btn-icon btn-outline-secondary  btn-sm me-2">
        Bio
      </a>
    )
  }

  const renderModal = () => {
    return (
      <>
        <Modal show={show} handleClose={handleClose}>
            <div class="modal-header">
                <h5 class="modal-title">{name}{credentials !== null ? ', ' + credentials : ''}</h5>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                </button>
            </div>
            <div class="modal-body">
              <div dangerouslySetInnerHTML={{ __html: bio }} />
            </div>
        </Modal>
      </>
    )
  }
  return (
    <div className="col">
      <div className="people-card card card-body card-hover bg-light border-0 text-center mx-2">
        <div className={imageContainerClasses}>
          <div className="bio-container">
            {bioSlug || bio ? (<span className="btn bio-btn" onClick={handleShow} >View Bio</span>) : ''}
            <GatsbyImage image={image} className="d-block rounded" imgClassName="rounded" alt={name}></GatsbyImage>
          </div>
        </div>
          <h5 className="fw-medium fs-lg mb-1">{name}{credentials !== null ? ', ' + credentials : ''} </h5>
          <p className="fs-sm mb-3">{title}</p>
          <div className="d-flex justify-content-center">
            {renderLinkedIn(linkedIn)}
            {bioLink ? renderBioLink(bioLink) : ''}
          </div>
        </div>

        {
          bioSlug || bio ? renderModal() : ''
        }

    </div>
  )

}
